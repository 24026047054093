import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from "@iconify/react"
import styles from './service.module.scss'

const Service = props => {
  const {name, description, icons} = props

  return (
    <div className={styles.root}>
      <div>
        {icons.map((icon, index) => (
          <Icon key={`icon-${name}-${index}`} className={styles.icon} icon={icon}/>
        ))}
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

Service.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  icons: PropTypes.array
};

export default Service;