import React from "react"
import baselineWeb from '@iconify/icons-ic/baseline-web'
import androidFilled from '@iconify/icons-ant-design/android-filled'
import appleFilled from '@iconify/icons-ant-design/apple-filled'
import cloudServerOutlined from '@iconify/icons-ant-design/cloud-server-outlined'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Service from "../components/service"
import Container from "../components/container"
import styles from './services.module.scss'

const ServicesPage = ({location}) => {
  const services = [
    {
      name: "Website development",
      description: "Development of portfolios, homepages or landing pages with latest tools for SEO optimization and responsive design",
      icon: [baselineWeb]
    },
    {
      name: "Webapp development",
      description: "Dynamic web applications with fast performance and responsive design",
      icon: [baselineWeb]
    },
    {
      name: "Native android development",
      description: "Native android app development with best practices for better performance",
      icon: [androidFilled]
    },
    {
      name: "Multiplatform development",
      description: "Development for Android and iOS with native performance",
      icon: [androidFilled, appleFilled]
    },
    {
      name: "Backend development",
      description: "",
      icon: [cloudServerOutlined]
    }
  ]

  return (
    <Layout className={styles.servicesRoot} location={location}>
      <SEO title="Home"/>
      <section>
        <Container>
          <h1>Services</h1>
          <div className={styles.services}>
            {services.map((service, index) => (
              <Service
                key={`${service.name}-${index}`}
                name={service.name}
                description={service.description}
                icons={service.icon}/>
            ))}
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default ServicesPage
